<template>
	<div>
		<BackButton :route="{ name: 'content-articles', label: 'News' }" />

		<ContentBuilder
			ref="builder"
			api-method="news"
			page-type="Article"
			parent-endpoint="articles"
			edit-route="content-articles-id"
			:clone-id="id"
			:url-prefix="urlPrefix"
			:restrict-url-prefix="true"
			:include-keywords="true"
			:include-teaser-text="true"
			:include-body-text="true"
			:no-section-builder="true"
			:hide-menu="true"
			:hide-section-icon="true"
			:hide-section-title="true"
			:hide-column-icon="true"
			:hide-column-title="true"
			@created="created"
		/>
	</div>
</template>

<script>
export default {
	components: {},
	setup() {},
	data() {
		return {
			urlPrefix: '/our-community/news/',
			id: this.$route.query.id || null,
		}
	},
	methods: {
		created(slug) {
			this.$router.push({ name: 'content-articles-id', params: { id: slug } })
		},
	},
}
</script>
